const { useEffect, useState } = require("react");

const MediaQuery = ({ ssr = false, screen = "desktop", children }) => {
  const [shouldShow, setShouldShow] = useState(ssr);

  useEffect(() => {
    const listener = () => {
      const min = screen === "mobile" ? 0 : 650;
      const max = screen === "mobile" ? 650 : Infinity;
      const shouldShow = window.innerWidth >= min && window.innerWidth <= max;

      setShouldShow(shouldShow);
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("orientationchange", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("orientationchange", listener);
    };
  }, [screen]);

  if (!shouldShow) return null;

  return children;
};

export default MediaQuery;
