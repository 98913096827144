import "./index.scss";

import Navbar from "components/NavBar";
import FormInput from "components/FormInput";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { formatToUSNumber } from "utils/format-to-us-number";

const BannerSection = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");

  const handleRedirectToSignup = useCallback(
    (e) => {
      e.preventDefault();
      navigate("/signup", { state: { phone } });
    },
    [navigate, phone]
  );

  const handlePhoneNumberChange = (e) => {
    if (e.target.value === "+") {
      return setPhone("");
    }
    const formattedNumber = formatToUSNumber(e.target.value);

    setPhone(formattedNumber);
  };

  useLayoutEffect(() => {
    var htmlInput = document.getElementById("phoneNumber");
    htmlInput.oninvalid = function (e) {
      if (!e.target.value) {
        e.target.setCustomValidity('"Phone number" is required');
      } else {
        e.target.setCustomValidity("");
      }
    };

    htmlInput.oninput = function (e) {
      e.target.setCustomValidity("");
    };
  }, []);

  return (
    <section>
      <div className="text-white w-full z-10">
        <Navbar isLandingPage />
      </div>
      <div className="mx-[10px]">
        <div className="relative h-[620px] background container">
          <div className="absolute top-1/2 left-6  md:left-[64px] translate-y-[-50%] text-white">
            <p className="heading-font text-shadow-xl font-bold text-5xl max-w-[70vw] md:max-w-max md:text-7xl sm:text-6xl md:leading-normal sm:leading-snug leading-tight">
              The best investing app for teens,
              <br /> friends & families
            </p>
            <p className="heading-font mt-5 text-xl leading-8  max-w-[75vw] md:max-w-max ">
              Pre-register now to join the waitlist
            </p>
            <form
              onSubmit={handleRedirectToSignup}
              className="flex gap-4 mt-5 md:max-w-[80%] max-w-[80vw]"
            >
              <FormInput
                value={phone}
                type="tel"
                required
                id="phoneNumber"
                variant="contained"
                maxLength={17}
                onChange={handlePhoneNumberChange}
                placeholder="Enter your Phone Number"
              />
              <button
                className="bg-st-yellow rounded-lg sm:py-1 w-[30%] px-3 py-3"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
