import Logo from "assets/logos/parent/stemmer_logo_md.jpeg";
import FormInput from "components/FormInput";
import MainLayout from "components/layouts/MainLayout";
import { useLayoutEffect } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";

const Desktop = ({
  onSubmit,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  isLoading,
  isInvalidEmail,
}) => {
  useLayoutEffect(() => {
    var htmlInput = document.getElementById("emailAddress");
    htmlInput.oninvalid = function (e) {
      e.target.setCustomValidity('"Email" is required');
    };
  }, []);

  return (
    <MainLayout>
      <div className="bg-st-primary sm:bg-white h-full flex flex-col sm:px-10 w-full">
        <div className="flex flex-col items-center justify-center gap-3">
          <div className="relative flex items-center justify-center">
            <Link to="/">
              <img
                src={Logo}
                alt="Logo"
                width="0"
                height="0"
                sizes="100%"
                className="w-full h-[26px]"
                priority="true"
              />
            </Link>
          </div>
          <div className="max-w-[80%] w-full text-center text-st-gray-text">
            Build friends & family networks and invest for your near and dear
            ones
          </div>
        </div>
        <div className="sm:hidden p-10">
          <p className="text-center text-white text-lg font-bold">
            The Best Investing App
          </p>
        </div>
        <form
          className="bg-white flex-1 flex flex-col justify-between sm:mt-10 p-4 sm:p-0 rounded-t-3xl sm:rounded-none"
          onSubmit={onSubmit}
        >
          <div>
            <div>
              <FormInput
                label="First Name"
                placeholder="Enter your first name"
                value={firstName}
                required
                minLength={3}
                maxLength={50}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="mt-7">
              <FormInput
                label="Last Name"
                placeholder="Enter your last name"
                value={lastName}
                required
                minLength={3}
                maxLength={50}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="mt-7">
              <FormInput
                label="Email"
                type="email"
                placeholder="Enter your email address"
                value={email}
                id="emailAddress"
                required
                isInvalid={isInvalidEmail}
                invalidMessage="Invalid Email Address or Phone Number"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="mt-7">
              <FormInput
                type="password"
                label="Password"
                placeholder="Enter password"
                value={password}
                required
                minLength={8}
                maxLength={100}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="mt-7">
              <FormInput
                type="password"
                label="Confirm Password"
                placeholder="Confirm password"
                value={confirmPassword}
                required
                minLength={8}
                maxLength={100}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-10">
            <button
              disabled={isLoading}
              className="w-full bg-st-primary text-white rounded-lg p-4 flex justify-center"
              type="submit"
            >
              {isLoading ? (
                <RotatingLines
                  visible={true}
                  height="24"
                  width="24"
                  strokeColor="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                />
              ) : (
                "Sign Up"
              )}
            </button>
          </div>
        </form>
      </div>
    </MainLayout>
  );
};

export default Desktop;
