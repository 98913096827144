import API from "config/API";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getPosts = createAsyncThunk("posts/list", async (thunkAPI) => {
  try {
    const response = await API.get("post");
    console.log("response: ", response);
    const data = response.data;
    if (data && data.info) {
      return data.info.listPost;
    } else return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const createPost = createAsyncThunk(
  "posts/create",
  async (post, thunkAPI) => {
    try {
      const response = await API.post("post", post);
      console.log("response: ", response);
      const data = response.data;
      if (data && data.info) {
        return data.info;
      } else return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);

export const likePost = createAsyncThunk(
  "posts/like",
  async (postOid, thunkAPI) => {
    try {
      const response = await API.post("like", { postOid });
      console.log("response: ", response);
      const data = response.data;
      if (data && data.info) {
        return data.info;
      } else return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);

export const deletePost = createAsyncThunk(
  "posts/delete",
  async (postOid, thunkAPI) => {
    try {
      const response = await API.delete("post", { postOid });
      console.log("response: ", response);
      const data = response.data;
      if (data && data.info) {
        return data.info;
      } else return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);

export const followPost = createAsyncThunk(
  "posts/follow",
  async (followOid, thunkAPI) => {
    try {
      const response = await API.post("follow", followOid);
      console.log("response: ", response);
      const data = response.data;
      if (data && data.info) {
        return data.info.listPost;
      } else return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);

const postsSlice = createSlice({
  name: "posts",
  initialState: {
    loading: false,
    errors: null,
    posts: [],
    isCreated: false,
    isLiked: false,
    isFollowed: false,
    isDeleted: false,
  },
  reducers: {
    resetPost: (state) => {
      state.isDeleted = false;
      state.isCreated = false;
      state.isLiked = false;
      state.isFollowed = false;
      state.loading = false;
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    // list posts
    builder.addCase(getPosts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPosts.fulfilled, (state, action) => {
      state.loading = false;
      state.posts = action.payload;
    });
    builder.addCase(getPosts.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.error;
      state.posts = [];
    });
    // create post
    builder.addCase(createPost.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPost.fulfilled, (state, action) => {
      state.loading = false;
      state.isCreated = true;
    });
    builder.addCase(createPost, (state, action) => {
      state.loading = false;
      state.isCreated = false;
      state.errors = action.error;
    });
    // delete post
    builder.addCase(deletePost.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePost.fulfilled, (state, action) => {
      state.loading = false;

      if (action.code === 200 || action.code === 201) {
        state.isDeleted = true;
      }
    });
    builder.addCase(deletePost.rejected, (state, action) => {
      state.loading = false;
      state.isDeleted = false;
      state.errors = action.error;
    });
    // like post
    builder.addCase(likePost.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(likePost.fulfilled, (state, action) => {
      state.loading = false;
      state.isLiked = true;
    });
    builder.addCase(likePost.rejected, (state, action) => {
      state.loading = false;
      state.isLiked = false;
      state.errors = false;
    });
    // follow
    builder.addCase(followPost.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(followPost.fulfilled, (state, action) => {
      state.loading = false;
      state.isFollowed = true;
    });
    builder.addCase(followPost.rejected, (state, action) => {
      state.loading = false;
      state.isFollowed = false;
      state.errors = action.error;
    });
  },
});

export const { resetPost } = postsSlice.actions;

export default postsSlice.reducer;
