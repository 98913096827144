import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { login } from "stores/auth";
import MediaQuery from "components/MediaQuery";
import Desktop from "./desktop";
import Mobile from "./mobile";
import { toast } from "react-toastify";

const NewLogin = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const { token, isLogin, errors } = useSelector((state) => state.authStore);

  useEffect(() => {
    if (isLogin && token) {
      localStorage.setItem("stemmer_token", token);
      window.location.reload();
    }
    if (errors && errors.login) {
      toast.error("Invalid email or password. Please try again.", "Error");
    }
    setIsLoading(false);
  }, [isLogin, errors, token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Log the values to the console
    console.log("Form submitted with values:");
    console.log("Phone Number:", phoneNumber);
    console.log("Password:", password);
    console.log("Remember Me:", rememberMe);

    setIsLoading(true);
    dispatch(
      login({
        userId: phoneNumber,
        passcode: password,
      })
    );
  };
  return (
    <>
      <MediaQuery screen="desktop">
        <Desktop
          handleSubmit={handleSubmit}
          password={password}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          setPassword={setPassword}
          setRememberMe={setRememberMe}
          isLoading={isLoading}
        />
      </MediaQuery>
      <MediaQuery screen="mobile">
        <Mobile
          handleSubmit={handleSubmit}
          password={password}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          setPassword={setPassword}
          rememberMe={rememberMe}
          isLoading={isLoading}
        />
      </MediaQuery>
    </>
  );
};

export default NewLogin;
