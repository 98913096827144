import Navbar from "components/NavBar";
import FaqItem from "components/parent/FaqItem";
import { Helmet } from "react-helmet";

const HelpCenter = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us</title>
        <meta
          name="keywords"
          content="About Stemmer Markets, Using Stemmer Markets, Managing your investment account, Safety and Security, Rules and Policies, Terms and Conditions"
        />
      </Helmet>

      <Navbar isLandingPage />

      <div className="grid grid-cols-1 md:grid-cols-2 container mx-auto gap-8 px-3">
        <div className="md:mt-12 sm:mt-10 border rounded shadow-3 p-10 min-h-[268px] hover:shadow-2 hover:cursor-pointer">
          <h2 className="font-bold text-2xl text-[#1D9BF0]">
            About Stemmer Markets
          </h2>
          <p className="mt-4 text-gray-500">
            Lorem ipsum Dashwood contempt on mr unlocked resolved provided of
            of. Stanhill wondered it it welcomed oh.
          </p>
        </div>
        <div className="md:mt-12 sm:mt-0 border rounded shadow-3 p-10 min-h-[268px] hover:shadow-2 hover:cursor-pointer">
          <h2 className="font-bold text-2xl text-[#1D9BF0]">
            Using Stemmer Markets
          </h2>
          <div className="mt-4 [&>div]:mb-2">
            <FaqItem
              question={"Why should I pre-register now and join the waitlist"}
              answer={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam"
              }
            />
            <FaqItem
              question={"What are friends & family networks"}
              answer={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam"
              }
            />
            <FaqItem
              question={"Who can be in my friends & family network"}
              answer={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam"
              }
            />
            <FaqItem
              question={
                "Are there any limits on the number of members in the friends & family network"
              }
              answer={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam"
              }
            />
            <FaqItem
              question={
                "After the promotion period are there any limits on the number of members in the friends & family networks"
              }
              answer={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam"
              }
            />
          </div>
        </div>
        <div className="border rounded shadow-3 p-10 min-h-[268px] hover:shadow-2 hover:cursor-pointer">
          <h2 className="font-bold text-2xl text-[#1D9BF0]">
            Managing your investment account
          </h2>
          <div className="mt-4 [&>div]:mb-2">
            <FaqItem
              question={"What is an investment account"}
              answer={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam"
              }
            />
            <FaqItem
              question={"How much does an investment account cost"}
              answer={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam"
              }
            />
            <FaqItem
              question={"For how long is the 70% discount valid"}
              answer={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam"
              }
            />
            <FaqItem
              question={
                "After the promotion period how much is the monthly fee"
              }
              answer={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam"
              }
            />
            <FaqItem
              question={"What is a trading account"}
              answer={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam"
              }
            />
          </div>
        </div>
        <div className="border rounded shadow-3 p-10 min-h-[268px] hover:shadow-2 hover:cursor-pointer">
          <h2 className="font-bold text-2xl text-[#1D9BF0]">
            Safety and Security
          </h2>
          <div className="mt-4 [&>div]:mb-2">
            <p className="mt-4 text-gray-500">
              Lorem ipsum Dashwood contempt on mr unlocked resolved provided of
              of. Stanhill wondered it it welcomed oh. Hundred no prudent he
              however smiling at an offence. If earnestly extremity he he
              propriety something admitting convinced ye. Pleasant in to
              although as if differed horrible. Mirth his quick its set front
              enjoy hoped had there. Who connection imprudence middletons too
              but increasing celebrated principles joy. Herself too improve gay
              winding ask expense are compact. New all paid few hard pure she.
            </p>
          </div>
        </div>

        <div className="border rounded shadow-3 p-10 min-h-[268px] hover:shadow-2 hover:cursor-pointer">
          <h2 className="font-bold text-2xl text-[#1D9BF0]">
            Rules and Policies
          </h2>
          <div className="mt-4 [&>div]:mb-2">
            <p className="mt-4 text-gray-500">
              Lorem ipsum Dashwood contempt on mr unlocked resolved provided of
              of. Stanhill wondered it it welcomed oh. Hundred no prudent he
              however smiling at an offence.
            </p>
          </div>
        </div>

        <div className="border rounded shadow-3 p-10 min-h-[268px] hover:shadow-2 hover:cursor-pointer">
          <h2 className="font-bold text-2xl text-[#1D9BF0]">
            Terms and Conditions
          </h2>
          <div className="mt-4 [&>div]:mb-2">
            <p className="mt-4 text-gray-500">
              Lorem ipsum Dashwood contempt on mr unlocked resolved provided of
              of. Stanhill wondered it it welcomed oh. Hundred no prudent he
              however smiling at an offence. If earnestly extremity he he
              propriety something admitting convinced ye. Pleasant in to
              although as if differed horrible.
            </p>
          </div>
        </div>
      </div>
      <div className="h-[100px]">&nbsp;</div>
    </div>
  );
};

export default HelpCenter;
