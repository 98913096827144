import { clsx } from "clsx";
import Icon from "components/Icons";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { resetUser } from "stores/auth";

const MobileLayout = ({
  children,
  title = null,
  alignTitle = "center",
  imgSrcUrl = null,
  backLink = null,
}) => {
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(resetUser());

    localStorage.removeItem("stemmer_token");
    window.location.href = "/#/login";
    window.location.reload();
  }, [dispatch]);

  return (
    <main className="flex flex-col min-h-screen bg-st-primary relative">
      {backLink && (
        <div className="w-full z-40 absolute top-4 left-0 right-0 text-white flex items-center justify-between px-4">
          <Link to={backLink}>
            <Icon name="back" />
          </Link>
          <div onClick={handleLogout} className="cursor-pointer">
            <Icon name="logout" />
          </div>
        </div>
      )}
      <div
        className={clsx(
          "relative h-[150px] overflow-hidden flex justify-center"
        )}
      >
        <h2
          className={clsx("text-lg font-bold text-white flex z-50 mb-[36px]", {
            "items-center": alignTitle === "center",
            "items-start mt-[11px]": alignTitle === "top",
          })}
        >
          {title}
        </h2>
        {imgSrcUrl && (
          <>
            <div className="absolute right-0 bottom-[-25px] bg-st-primary z-10">
              <div className="bg-[rgba(0,0,0,.45)] absolute w-full h-full top-0 left-0 right-0 bottom-0 z-30]">
                &nbsp;
              </div>
              <img src={imgSrcUrl} alt="Banner" className="w-full" />
            </div>
          </>
        )}
        <div className="bg-white block rounded-tl-[36px] h-[36px] rounded-tr-[36px] absolute bottom-0 left-0 right-0 z-30"></div>
      </div>
      <div
        style={{ height: "calc(100vh - 125px)" }}
        className="bg-white min-h-[680px] py-[12px] px-[16px]"
      >
        {children}
      </div>
    </main>
  );
};

export default MobileLayout;
