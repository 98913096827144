import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";

const PublicRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Navigate to="/invite" /> : <Component {...rest} />;
};

export default PublicRoute;
