import { configureStore } from "@reduxjs/toolkit";

import authStore from "./auth";
import feedsStore from "./feeds";
import postsStore from "./posts";
import contactStore from "./contact";
import searchStore from "./search";

export const store = configureStore({
  reducer: {
    authStore,
    feedsStore,
    postsStore,
    contactStore,
    searchStore,
  },
});
