import React, { useLayoutEffect } from "react";
import MobileLogo from "assets/logos/parent/stemmer_logo_md.jpeg";
import Mobile2Columns from "assets/logos/parent/stemmer_logo_sm.jpeg";
import FormInput from "components/FormInput";
import MobileLayout from "components/layouts/MobileLayout";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { formatToUSNumber } from "utils/format-to-us-number";

const Mobile = ({
  handleSubmit,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  password,
  setPassword,
  rePassword,
  setRePassword,
  isLoading,
}) => {
  useLayoutEffect(() => {
    var htmlInputPhone = document.getElementById("phoneNumber");
    var htmlInputEmail = document.getElementById("email");
    htmlInputEmail.oninvalid = function (e) {
      e.target.setCustomValidity('"Email" is required');
    };
    htmlInputPhone.oninvalid = function (e) {
      e.target.setCustomValidity('"Phone number" is required');
    };
  }, []);

  return (
    <MobileLayout title="">
      <div className="flex flex-col items-center justify-center gap-3">
        <Link to="/">
          <div className="relative flex items-center justify-center">
            <img
              src={MobileLogo}
              alt="Logo"
              sizes="100%"
              className="w-full max-[420px]:hidden sm:hidden block h-[26px]"
              priority="true"
            />
            <img
              src={Mobile2Columns}
              alt="Logo"
              sizes="100%"
              className="w-full min-[420px]:hidden sm:hidden block h-[36px]"
              priority="true"
            />
          </div>
        </Link>
        <div className="max-w-[80%] w-full text-center text-st-gray-text"></div>
      </div>
      <form
        className="flex flex-col h-full max-w-[540px] mx-auto mt-3"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-3">
          <FormInput
            screen="mobile"
            label="First Name"
            placeholder="Enter your first name"
            value={firstName}
            required
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
          />
          <FormInput
            screen="mobile"
            label="Last Name"
            placeholder="Enter your last name"
            value={lastName}
            required
            type="text"
            onChange={(e) => setLastName(e.target.value)}
          />
          <FormInput
            screen="mobile"
            label="Email"
            placeholder="Enter your email address"
            value={email}
            id="email"
            required
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <FormInput
            screen="mobile"
            label="Phone Number"
            placeholder="Enter your phone number"
            value={phoneNumber}
            required
            type="text"
            maxLength={17}
            id="phoneNumber"
            onChange={(e) => {
              const value = e?.target?.value
                ?.replace("+1", "")
                .replace(")", "")
                .replace("(", "")
                .replace(" ", "")
                .replace("-", "");

              setPhoneNumber(formatToUSNumber(value));
            }}
          />
          <FormInput
            screen="mobile"
            type="password"
            label="Password"
            placeholder="Enter password"
            value={password}
            required
            minLength={8}
            maxLength={50}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormInput
            screen="mobile"
            type="password"
            label="Confirm Password"
            placeholder="Enter password again"
            value={rePassword}
            required
            minLength={8}
            maxLength={50}
            onChange={(e) => setRePassword(e.target.value)}
          />
        </div>
        <div className="flex-1 flex items-start mt-[32px]">
          <button
            disabled={isLoading}
            className="w-full bg-st-primary text-white text-base font-semibold rounded-lg p-[14px] flex justify-center"
            type="submit"
          >
            {isLoading ? (
              <RotatingLines
                visible={true}
                height="24"
                width="24"
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
              />
            ) : (
              "Register"
            )}
          </button>
        </div>
        <div className="my-7 text-center">
          <p className="text-st-primary text-xs w-max mx-auto">
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </div>
      </form>
    </MobileLayout>
  );
};

export default Mobile;
