import Iphone from "assets/images/iphone-15.webp";
import Icon from "components/Icons";

const PreRegisterSection = () => {
  return (
    <section className="container">
      <div className="relative">
        <div className="heading-font min-h-[434px] flex flex-col justify-start xl:items-start items-center shadow-1 overflow-hidden rounded-2xl border p-10 py-14 text-dark-blue">
          <h2 className="font-bold text-3xl">
            Get 70% off an investment account
          </h2>
          {/* <div className="flex justify-center xl:justify-start gap-2">
            <button className="bg-st-violet py-[12px] px-[18px] rounded-full mt-4 text-xl">
              <span className="inline-block line-through">$15 per month</span>
            </button>
            <button className="bg-st-violet flex justify-center items-center gap-2 py-[12px] px-[18px] rounded-full mt-4 text-xl">
              <span className="inline-block">$4.50 per month</span>
              <Icon name="tick" />
            </button>
          </div> */}
          <p className="font-bold text-2xl py-[28px]">And</p>
          <p className="font-bold text-2xl">
            A Free Trading Account (when available)
          </p>
          <p className="text-lg mt-5">
            (Offer valid for a limited time and for a limited number of
            customers)
          </p>
        </div>
        <div className="hidden absolute bottom-4 right-20 mt-[84px] xl:block">
          <img
            src={Iphone}
            alt="Iphone 15"
            sizes="100%"
            className="h-[504px] z-10"
          />
        </div>
      </div>
    </section>
  );
};

export default PreRegisterSection;
