import { useMemo } from "react";

export const useAuth = () => {
  const tokenFromLocalStorage = localStorage.getItem("stemmer_token");
  const isAuthenticated = useMemo(() => {
    const token = tokenFromLocalStorage;

    if (token) {
      return true;
    }

    return false;
  }, [tokenFromLocalStorage]);

  return {
    isAuthenticated,
  };
};
