import LogoWhite from "assets/images/logo-white.svg";
import Facebook from "assets/images/facebook.svg";
import Instagram from "assets/images/instagram.svg";
import Twitter from "assets/images/twitter.svg";
import { Link } from "react-router-dom";

const Footer = ({ hideFooterOnMobile = true }) => {
  const currentYear = new Date().getFullYear();

  return (
    <section className="mx-[10px]">
      <footer
        className={`max-w-[1440px] rounded-tl-md rounded-tr-md ml-auto mr-auto bg-st-primary mt-[110px] text-white ${
          hideFooterOnMobile && "hidden sm:block"
        }`}
      >
        <div className="">
          <div className="py-[46px] px-[24px]">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 md:gap-8">
              <div>
                <div>
                  <img
                    src={LogoWhite}
                    alt="Footer Logo"
                    width={202}
                    height={30}
                  />
                </div>
                <div>
                  {/* <p className="text-sm mt-4 pl-1">
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s
                </p> */}
                </div>
              </div>
              <div className="flex flex-col md:items-center">
                <div>
                  <p className="font-medium">Quick Links</p>
                  <div className="flex gap-4 mt-4">
                    <p>
                      <Link to="/">Home</Link>
                    </p>
                    {/* <span>|</span>
                  <p>
                    <Link to="/faqs">FAQs</Link>
                  </p> */}
                    <span>|</span>
                    <p>
                      <Link to="/help">Help Center</Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:col-span-2 flex flex-col md:items-end">
                <div>
                  <p className="font-medium">Follow Us</p>
                  <div className="flex gap-4 mt-4">
                    <a href="/">
                      <img
                        src={Facebook}
                        alt="Facebook Icon"
                        width="0"
                        height="0"
                        sizes="100%"
                        className="w-full h-auto"
                      />
                    </a>
                    <a href="/">
                      <img
                        src={Instagram}
                        alt="Instagram Icon"
                        width="0"
                        height="0"
                        sizes="100%"
                        className="w-full h-auto"
                      />
                    </a>
                    <a href="/">
                      <img
                        src={Twitter}
                        alt="Twitter Icon"
                        width="0"
                        height="0"
                        sizes="100%"
                        className="w-full h-auto"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t p-4">
            <p className="text-center">
              &copy; {currentYear} Stemmer Markets LLC. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
