import { useCallback, useEffect } from "react";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import Modal from "components/Modal";
import ShareLink from "components/ShareLink";
import Icon from "components/Icons";
import { toast } from "react-toastify";

const InviteModal = ({ open, setIsOpen, inviteLink }) => {
  const [copied, copyToClipboard] = useCopyToClipboard();

  const handleCopyClick = useCallback(
    (text) => {
      copyToClipboard(text);
    },
    [copyToClipboard]
  );

  useEffect(() => {
    if (copied) {
      toast.success("The link has been successfully copied to the clipboard.");
    }
  }, [copied]);

  return (
    <Modal width="500px" open={open} onClose={() => setIsOpen(false)}>
      <div className="p-1">
        <div>
          <h3 className="text-xl font-bold pb-8 flex justify-between items-center">
            <span>Share</span>
            <span onClick={() => setIsOpen(false)}>
              <Icon name="cross" />
            </span>
          </h3>
        </div>
        <ShareLink shareUrl={inviteLink} />
        <h5 className="text-st-gray-text pt-8 font-medium">Or copy link</h5>
        <div className="mt-[10px] border rounded flex items-center justify-between">
          <div className="flex items-center gap-2 p-[14px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="none"
              viewBox="0 0 24 24"
            >
              <g stroke="#53555a" strokeLinecap="round" strokeWidth="1.5">
                <path d="M10.046 14c-1.506-1.512-1.37-4.1.303-5.779l4.848-4.866c1.673-1.68 4.25-1.816 5.757-.305 1.506 1.512 1.37 4.1-.303 5.78l-2.424 2.433"></path>
                <path d="M13.954 10c1.506 1.512 1.37 4.1-.303 5.779l-2.424 2.433-2.424 2.433c-1.673 1.68-4.25 1.816-5.757.305-1.506-1.512-1.37-4.1.303-5.78l2.424-2.433"></path>
              </g>
            </svg>
            <div className="flex">
              <span className="max-w-[300px] overflow-hidden whitespace-nowrap">
                {inviteLink}
              </span>
            </div>
          </div>
          <button
            onClick={() => handleCopyClick(inviteLink)}
            className="bg-st-primary py-[10px] mr-[4px] text-white px-[14px] rounded"
          >
            Copy
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default InviteModal;
