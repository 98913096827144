const { RotatingLines } = require("react-loader-spinner");

const WithSpinner = ({ loading, children, type = "fixed" }) => {
  return (
    <div className="z-50 select-none">
      {loading && (
        <div
          style={{ position: type, background: "rgba(0,0,0,.75)" }}
          className="w-full h-full z-50"
        >
          <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] z-30">
            <RotatingLines
              visible={true}
              height="100"
              width="100"
              ariaLabel="vortex-loading"
              wrapperClass="vortex-wrapper"
              strokeColor="#7C7DF1"
            />
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default WithSpinner;
