import ImagePlaceholder from "assets/images/profile_placeholder.webp";
import { RotatingLines } from "react-loader-spinner";
import InviteForm from "components/InviteForm/mobile";
import InviteModal from "components/InviteModal/mobile";
import { Link } from "react-router-dom";
import Icon from "components/Icons";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { resetUser } from "stores/auth";

const Mobile = ({
  contacts,
  isLoading,
  handleInviteButtonClick,
  openInviteModal,
  setOpenInviteModal,
  inviteLink,
  waitlist,
  refetchContacts,
}) => {
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(resetUser());

    localStorage.removeItem("stemmer_token");
    window.location.href = "/#/login";
    window.location.reload();
  }, [dispatch]);

  return (
    <main className="bg-white relative px-[16px] max-w-[540px] mx-auto">
      <div className="flex items-center justify-end mt-[10px] text-black">
        <div
          onClick={handleLogout}
          className="flex items-center gap-2 cursor-pointer"
        >
          <Icon name="logout" />
          <span className="font-medium text-xs">Logout</span>
        </div>
      </div>
      <section className="my-6">
        <div className="relative flex justify-center gap-x-[34px] gap-y-[12px] w-full flex-wrap">
          {contacts?.map((contact) => {
            return (
              <div
                key={contact?.memberOid}
                className="relative cursor-pointer flex justify-center flex-col items-center"
              >
                <img
                  src={contact?.profilePictureURL || ImagePlaceholder}
                  alt="Avatar"
                  width="0"
                  height="0"
                  sizes="100%"
                  className="w-[60px] h-[60px] rounded-full border-2 border-st-primary"
                />
                <p className="flex justify-center mt-[5px] text-xs sm:text-base">
                  {contact?.name || null}
                </p>
              </div>
            );
          })}
        </div>
        <div className="flex items-center justify-center gap-x-4 mt-4 flex-wrap gap-y-2">
          <h2>
            Your waitlist number:{" "}
            <span className="text-primary">{waitlist?.waitlistNumber}</span>
          </h2>
          <h2>
            Your waitlist Position:{" "}
            <span className="text-primary">{waitlist?.myPosition}</span>
          </h2>
        </div>
        <div className="flex justify-center mt-[22px]">
          <button
            disabled={isLoading}
            className="bg-st-primary text-white rounded-lg text-sm font-semibold leading-[21.5px] py-[14px] w-full flex justify-center"
            onClick={handleInviteButtonClick}
          >
            {isLoading ? (
              <RotatingLines
                visible={true}
                height="22"
                width="22"
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
              />
            ) : (
              "Invite your friends & family members"
            )}
          </button>
        </div>
        <div className="flex flex-col gap-[22px] mt-[22px] mb-7">
          <InviteForm
            title="Pre-register Teens"
            description="(children, grand kids, nephews & nieces)"
          />
          <InviteForm
            title="Pre-register kids under 13 years old"
            description="(children, grand kids, nephews & nieces)"
            onFinish={refetchContacts}
          />
        </div>
      </section>
      <InviteModal
        open={openInviteModal}
        setIsOpen={setOpenInviteModal}
        inviteLink={inviteLink}
      />
      <Link to="/profile">
        <div className="fixed bottom-[64px] cursor-pointer right-[16px] border border-white text-white bg-st-primary inline-block p-[14px] rounded-[10px]">
          <Icon name="settings" />
        </div>
      </Link>
    </main>
  );
};

export default Mobile;
