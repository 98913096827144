import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WeiboIcon,
  WeiboShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const ShareLink = ({ shareUrl, title = "Invitation Link" }) => {
  return (
    <div>
      <div className="flex flex-wrap justify-center gap-x-8 gap-y-4">
        <div>
          <FacebookShareButton url={shareUrl}>
            <FacebookIcon size={68} round />
          </FacebookShareButton>
        </div>

        <div>
          <TwitterShareButton url={shareUrl} title={title}>
            <TwitterIcon size={68} round />
          </TwitterShareButton>
        </div>

        <div>
          <WhatsappShareButton url={shareUrl} title={title}>
            <WhatsappIcon size={68} round />
          </WhatsappShareButton>
        </div>

        <div>
          <TelegramShareButton url={shareUrl} title={title}>
            <TelegramIcon size={68} round />
          </TelegramShareButton>
        </div>

        <div>
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon size={68} round />
          </LinkedinShareButton>
        </div>

        <div>
          <PinterestShareButton url={String(window.location)}>
            <PinterestIcon size={68} round />
          </PinterestShareButton>
        </div>
        <div>
          <RedditShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={680}
          >
            <RedditIcon size={68} round />
          </RedditShareButton>
        </div>

        <div>
          <EmailShareButton url={shareUrl} subject={title} body="body">
            <EmailIcon size={68} round />
          </EmailShareButton>
        </div>

        <div>
          <TumblrShareButton url={shareUrl} title={title}>
            <TumblrIcon size={68} round />
          </TumblrShareButton>
        </div>

        <div>
          <FacebookMessengerShareButton url={shareUrl} appId="521270401588372">
            <FacebookMessengerIcon size={68} round />
          </FacebookMessengerShareButton>
        </div>

        <div>
          <ViberShareButton url={shareUrl} title={title}>
            <ViberIcon size={68} round />
          </ViberShareButton>
        </div>

        <div>
          <WeiboShareButton url={shareUrl} title={title}>
            <WeiboIcon size={68} round />
          </WeiboShareButton>
        </div>
      </div>
    </div>
  );
};

export default ShareLink;
