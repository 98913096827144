import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Component {...rest} />;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoute;
