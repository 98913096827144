import { useState, useCallback } from "react";
import { toast } from "react-toastify";

const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = useCallback((text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        toast.success("Link successfully copied to clipboard.");
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  }, []);

  return [copied, copyToClipboard];
};

export default useCopyToClipboard;
