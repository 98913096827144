import { useNavigate } from "react-router-dom";
import WithSpinner from "components/WithSpinner";
import FormEditableInput from "components/FormEditableInput";
import CameraIcon from "assets/images/camera-icon.svg";
import ProfilePicPlaceholder from "assets/images/profile_placeholder.webp";
import { RotatingLines } from "react-loader-spinner";
import MobileLayout from "components/layouts/MobileLayout";

const Mobile = ({
  user,
  avatar,
  handleFileChange,
  inputFileRef,
  handleClick,
  handleSubmitForm,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  dateOfBirth,
  setDateOfBirth,
  email,
  setEmail,
  isSubmitting,
}) => {
  const navigate = useNavigate();

  return (
    <WithSpinner loading={!user}>
      <MobileLayout backLink="/invite" title="Profile Setting" alignTitle="top">
        <div className="absolute top-[49px] translate-x-1/2 right-[50%] z-40">
          <div
            key={avatar}
            className="relative w-[152px] h-[152px] shadow-2 rounded-full"
          >
            {avatar ? (
              <img
                src={avatar}
                alt="Profile"
                width="0"
                height="0"
                sizes="100%"
                loading="lazy"
                className="w-[152px] h-[152px] rounded-full"
              />
            ) : (
              <img
                src={ProfilePicPlaceholder}
                alt="Profile"
                width="0"
                height="0"
                sizes="100%"
                className="rounded-full w-[152px] h-[152px]"
              />
            )}
            <input
              type="file"
              ref={inputFileRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <span className="absolute bottom-4 right-0 z-40">
              <img
                src={CameraIcon}
                alt="Camera icon"
                className="cursor-pointer"
                width={38}
                height={38}
                onClick={handleClick}
              />
            </span>
          </div>
        </div>
        <form
          className="mt-[22px] flex flex-col justify-between max-w-[540px] mx-auto"
          onSubmit={handleSubmitForm}
        >
          <div>
            <div>
              <FormEditableInput
                label="First Name"
                placeholder="David"
                icon="pencil"
                screen="mobile"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                minLength={3}
                maxLength={50}
              />
            </div>
            <div className="mt-[22px]">
              <FormEditableInput
                label="Last Name"
                placeholder="John"
                icon="pencil"
                screen="mobile"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                minLength={3}
                maxLength={50}
              />
            </div>
            <div className="mt-[22px]">
              <FormEditableInput
                label="Date of Birth"
                placeholder="DD/MM/YYYY"
                screen="mobile"
                type="date"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                required
                minLength={3}
                maxLength={50}
              />
            </div>
            <div className="mt-[22px]">
              <FormEditableInput
                label="Email-Address"
                placeholder="example@gmail.com"
                icon="pencil"
                screen="mobile"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                minLength={3}
                maxLength={100}
              />
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-2 justify-end py-7">
            <button
              disabled={isSubmitting}
              type="submit"
              className="w-full bg-st-primary text-white text-base font-semibold rounded-lg p-[14px] flex justify-center"
            >
              {isSubmitting ? (
                <RotatingLines
                  visible={true}
                  height="24"
                  width="24"
                  strokeColor="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                />
              ) : (
                "Save Changes"
              )}
            </button>
            <button
              disabled={isSubmitting}
              type="submit"
              onClick={() => {
                navigate("/invite");
              }}
              className="w-full bg-white border text-st-gray-text rounded-lg p-[14px] flex justify-center"
            >
              Cancel
            </button>
          </div>
        </form>
      </MobileLayout>
    </WithSpinner>
  );
};

export default Mobile;
