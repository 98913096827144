import "../login/index.scss";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { checkPasswordRecoveryLink, reset } from "stores/auth";
import MediaQuery from "components/MediaQuery";

import Desktop from "./desktop";
import Mobile from "./mobile";

const SecureLink = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const username = searchParams.get("username");
  const hash = searchParams.get("hash");

  const [isResettingPassword, setIsResettingPassword] = useState(false);

  const isPassRecoveryLinkChecked = useRef(false);

  const { isRecovery, recoveryMessage, recoveryToken } = useSelector(
    (state) => state.authStore
  );
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Checking if password recovery link is still valid
  useEffect(() => {
    const main = () => {
      isPassRecoveryLinkChecked.current = true;
      dispatch(
        checkPasswordRecoveryLink({
          username,
          hash,
        })
      );
    };

    if (username && hash && !isPassRecoveryLinkChecked.current) {
      main();
    }
  }, [username, hash, dispatch]);

  const handleSubmit = (e) => {
    e?.preventDefault();

    if (password !== confirmPassword) {
      toast.error('"Password" and "Confirm Password" do not match.');
      return;
    }

    dispatch(
      reset({
        message: recoveryMessage,
        password: password,
        token: recoveryToken,
        setIsLoading: setIsResettingPassword,
      })
    );
  };

  return (
    <>
      {!isRecovery || !recoveryMessage || !recoveryToken ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RotatingLines
            strokeColor="#7C7DF1"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      ) : (
        <>
          <MediaQuery screen="desktop">
            <Desktop
              handleSubmit={handleSubmit}
              confirmPassword={confirmPassword}
              password={password}
              setConfirmPassword={setConfirmPassword}
              setPassword={setPassword}
              isLoading={isResettingPassword}
            />
          </MediaQuery>
          <MediaQuery screen="mobile">
            <Mobile
              handleSubmit={handleSubmit}
              confirmPassword={confirmPassword}
              password={password}
              setConfirmPassword={setConfirmPassword}
              setPassword={setPassword}
              isLoading={isResettingPassword}
            />
          </MediaQuery>
        </>
      )}
    </>
  );
};

export default SecureLink;
