import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { RotatingLines } from "react-loader-spinner";

import FormInput from "components/FormInput";
import { verifyUser, registerKids } from "stores/auth";
import InviteModal from "components/InviteModal";
import { formatToUSNumber } from "utils/format-to-us-number";

const InviteForm = ({ title, description, onFinish }) => {
  const dispatch = useDispatch();

  const [isTouched, setIsTouched] = useState(false);
  const [isInvalidInput, setIsInvalidInput] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [inviteLink, setInviteLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [isPreRegisterTeens] = useState(() => title === "Pre-register Teens");

  const validateEmail = (email) => {
    const pattern = /^(?:\+1[-.\s]?)?(?:\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
    return pattern.test(email);
  };

  const handlePhoneNumberChange = (e) => {
    if (e.target.value === "+") {
      return setPhoneNumber("");
    }
    const formattedNumber = formatToUSNumber(e.target.value);

    setPhoneNumber(formattedNumber);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from refreshing the page

    if (isPreRegisterTeens) {
      setIsTouched(true);
      if (!validateEmail(phoneNumber)) {
        return setIsInvalidInput(true);
      }
    }

    setIsLoading(true);
    if (isPreRegisterTeens) {
      dispatch(
        verifyUser({
          prospectPhoneNumber: phoneNumber,
        })
      )
        .then((res) => {
          if (res?.error?.message) {
            return toast.error(
              "Unable to generate invite link. Please try again."
            );
          }

          setInviteLink(res?.payload?.url);
          setOpenInviteModal(true);
        })
        .finally(() => {
          setIsLoading(false);
          setPhoneNumber("");
        });
    } else {
      dispatch(
        registerKids({
          id: phoneNumber,
        })
      )
        .then((res) => {
          if (res?.error?.message) {
            return toast.error(
              "Unable to generate invite link. Please try again."
            );
          }

          onFinish?.();
          window.scrollTo(0, 0);

          // setInviteLink(res?.payload?.url);
          // setOpenInviteModal(true);
        })
        .finally(() => {
          setIsLoading(false);
          setPhoneNumber("");
        });
    }
  };

  return (
    <div className="w-[540px] bg-white p-4 md:py-12 md:px-8 rounded-lg shadow">
      <p className="font-medium text-lg">{title}</p>
      <p className="text-black text-opacity-60 mt-2">{description}</p>
      <form onSubmit={handleSubmit}>
        <div className="mt-4">
          <FormInput
            placeholder={
              isPreRegisterTeens ? "Enter phone number" : "Enter Name"
            }
            value={phoneNumber} // Bind the state to the input value
            required
            type="text"
            isInvalid={isInvalidInput}
            invalidMessage="Invalid Phone Number!"
            maxLength={isPreRegisterTeens ? 17 : 100}
            onChange={(e) => {
              if (isPreRegisterTeens) {
                setIsInvalidInput(false);
                if (!validateEmail(e.target.value) && isTouched) {
                  setIsInvalidInput(true);
                }
                handlePhoneNumberChange(e);
              } else {
                setPhoneNumber(e.target.value);
              }
            }}
          />
        </div>
        <button
          className="bg-st-primary text-white rounded-lg w-full py-4 mt-8 flex justify-center"
          type="submit"
        >
          {isLoading ? (
            <RotatingLines
              visible={true}
              height="24"
              width="24"
              strokeColor="white"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
            />
          ) : (
            "Submit"
          )}
        </button>
      </form>
      <InviteModal
        open={openInviteModal}
        setIsOpen={setOpenInviteModal}
        inviteLink={inviteLink}
      />
    </div>
  );
};

export default InviteForm;
