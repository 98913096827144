const Modal = ({
  open = false,
  onClose = () => {},
  children,
  width = "max-content",
  height = "max-content",
}) => {
  return open ? (
    <>
      <div
        style={{ maxWidth: width, maxHeight: height }}
        className="fixed top-1/2 w-full h-max left-1/2 translate-x-[-50%] translate-y-[-50%] border border-st-gray-text p-4 rounded-lg z-50 bg-white overflow-hidden"
      >
        {children}
      </div>
      <div
        onClick={onClose}
        className="fixed top-0 left-0 right-0 bottom-0 z-10 bg-[rgba(0,0,0,.45)]"
      />
    </>
  ) : null;
};

export default Modal;
