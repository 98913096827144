import BannerSection from "components/parent/BannerSection";
import PreRegisterSection from "components/parent/PreRegisterSection";
import RegisterBannerSection from "components/parent/RegisterBannerSection";
import Footer from "components/Footer";
// import FaqSection from "components/parent/FaqSection";

const Parent = () => {
  return (
    <main>
      <header>
        <BannerSection />
      </header>
      <section className="mx-auto max-w-[1313px] w-full px-[18px] sm:px-[25px] mt-[64px]  xl:mt-[144px]">
        <PreRegisterSection />
      </section>
      <section className="mx-[10px] sm:mx-[25px]">
        <RegisterBannerSection />
      </section>
      {/* <section className="mx-0 lg:mx-[44px]">
        <FaqSection />
      </section> */}
      <Footer hideFooterOnMobile={false} />
    </main>
  );
};

export default Parent;
