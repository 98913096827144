import API from "config/API";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getContacts = createAsyncThunk(
  "contact/list",
  async (thunkAPI) => {
    try {
      const response = await API.get("contact");
      console.log("response: ", response);
      const data = response.data;
      if (data && data.info) {
        return data.info.listContact;
      } else return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    loading: false,
    errors: null,
    contacts: [],
  },
  reducers: {
    resetContact: (state) => {
      state.loading = false;
      state.errors = null;
      state.console = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContacts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getContacts.fulfilled, (state, action) => {
      state.loading = false;
      state.contacts = action.payload;
    });
    builder.addCase(getContacts.rejected, (state, action) => {
      state.loading = false;
      state.contacts = [];
      state.errors = action.error;
    });
  },
});

export default contactSlice.reducer;
