export const formatToUSNumber = (input) => {
  let numericInput = input.replace(/\D/g, "");

  if (numericInput.startsWith("1")) {
    numericInput = numericInput.substring(1);
  }

  let formattedNumber = "+1";

  if (numericInput.length > 0) {
    formattedNumber += " (";
  }

  if (numericInput.length > 3) {
    formattedNumber += numericInput.substring(0, 3) + ") ";
    numericInput = numericInput.substring(3);
  } else if (numericInput.length > 0) {
    formattedNumber += numericInput;
    numericInput = "";
  }

  if (numericInput.length > 3) {
    formattedNumber += numericInput.substring(0, 3) + "-";
    numericInput = numericInput.substring(3);
  } else if (numericInput.length > 0) {
    formattedNumber += numericInput;
    numericInput = "";
  }

  formattedNumber += numericInput;

  return formattedNumber;
};
