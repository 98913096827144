import { clsx } from "clsx";
import Icon from "components/Icons";

const FormInput = ({
  screen = "desktop",
  type = "text",
  label,
  placeholder,
  icon,
  value,
  onChange,
  required,
  minLength,
  maxLength,
}) => {
  let inputElement = null;

  if (type === "text" || type === "date") {
    inputElement = (
      <>
        {label && (
          <label
            className={clsx("block", {
              " text-st-gray-text": screen === "desktop",
              " text-st-gray-text text-sm": screen === "mobile",
            })}
          >
            {label}
          </label>
        )}
        <span className="relative">
          <input
            type={type}
            value={value}
            onChange={onChange}
            required={required}
            minLength={minLength}
            maxLength={maxLength}
            className={clsx(
              `w-full border-b border-black border-opacity-15 font-semibold outline-none mt-3`,
              {
                "pr-10": icon,
                "text-sm pb-3": screen === "mobile",
                "py-3": screen === "desktop",
              }
            )}
            placeholder={placeholder}
          />
          {icon === "pencil" && (
            <button
              type="button"
              className="absolute inset-y-0 right-1 flex items-center px-3 focus:outline-none"
            >
              <Icon name="pencil" />
            </button>
          )}
        </span>
      </>
    );
  }

  return inputElement;
};

export default FormInput;
