import API from "config/API";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getFeeds = createAsyncThunk("feeds/list", async (thunkAPI) => {
  try {
    const response = await API.get("feed");
    console.log("response: ", response);
    const data = response.data;
    if (data && data.info) {
      return data.info.listPost;
    } else return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return thunkAPI.rejectWithValue(err.response.data);
    // thunkAPI.rejectWithValue(error);
  }
});

export const likeFeed = createAsyncThunk(
  "feeds/like",
  async (postOid, thunkAPI) => {
    try {
      const response = await API.post("like", { postOid });
      console.log("response: ", response);
      const data = response.data;
      if (data && data.info) {
        return data.info;
      } else return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);

export const followFeed = createAsyncThunk(
  "feed/follow",
  async (followOid, thunkAPI) => {
    try {
      const response = await API.post("follow", followOid);
      console.log("response: ", response);
      const data = response.data;
      if (data && data.info) {
        return data.info.listPost;
      } else return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);

const feedsSlice = createSlice({
  name: "feeds",
  initialState: {
    loading: false,
    errors: null,
    feeds: [],
    isLiked: false,
    isFollowed: false,
  },
  reducers: {
    resetFeed: (state) => {
      state.isFollowed = false;
      state.isLiked = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFeeds.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFeeds.fulfilled, (state, action) => {
      state.loading = false;
      state.feeds = action.payload;
    });
    builder.addCase(getFeeds.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.error;
      state.feeds = [];
    });
    // like post
    builder.addCase(likeFeed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(likeFeed.fulfilled, (state, action) => {
      state.loading = false;
      state.isLiked = true;
    });
    builder.addCase(likeFeed.rejected, (state, action) => {
      state.loading = false;
      state.isLiked = false;
      state.errors = false;
    });
    // follow
    builder.addCase(followFeed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(followFeed.fulfilled, (state, action) => {
      state.loading = false;
      state.isFollowed = true;
    });
    builder.addCase(followFeed.rejected, (state, action) => {
      state.loading = false;
      state.isFollowed = false;
      state.errors = action.error;
    });
  },
});

export const { resetFeed } = feedsSlice.actions;

export default feedsSlice.reducer;
