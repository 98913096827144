import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "config/API";

export const getListAutocomplete = createAsyncThunk(
  "search/autocomplete",
  async (keyword) => {
    const response = await Axios.get(`search?searchstr=${keyword}`);
    console.log(">> response: ", response);
    if (response.data.info && response.data.info.code === 401) {
      window.location.href = "/#/login";
    }
    return response.data;
  }
);

const initialState = {
  loading: false,
  errors: null,
  results: null,
  items: [],
  keyword: "",
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    resetKeyword: (state) => {
      state.keyword = "";
    },
    updateKeyword: (state, action) => {
      state.keyword = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListAutocomplete.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getListAutocomplete.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload;
    });
    builder.addCase(getListAutocomplete.rejected, (state, action) => {
      state.loading = false;
      state.errors = {
        autocomplete: action.error,
      };
    });
  },
});

export const { resetKeyword, updateKeyword } = searchSlice.actions;

export default searchSlice.reducer;
