const Modal = ({ open = false, onClose = () => {}, children }) => {
  return open ? (
    <>
      <div
        style={{ maxHeight: "600px" }}
        className="fixed bottom-0 w-full h-max right-0 border border-st-gray-text py-4 px-[16px] rounded-tl-[16px] rounded-tr-[16px] z-50 bg-white overflow-hidden"
      >
        {children}
      </div>
      <div
        onClick={onClose}
        className="fixed top-0 left-0 right-0 bottom-0 z-10 bg-[rgba(0,0,0,.45)]"
      />
    </>
  ) : null;
};

export default Modal;
